/* Global Styles */
html {
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Arial", sans-serif;
  color: #333 !important;
  background-color: #ffffff;
  overflow-x: hidden;
}

/* Header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 5%;
  background-color: white;
}

.header .logo img {
  width: 70px;
}

.header .nav ul {
  list-style: none;
  display: flex;
  gap: 20px;
  align-items: center;
}

.header .nav a {
  text-decoration: none;
  color: #333 !important;
  font-weight: bold;
}

.header .btn {
  padding: 10px 20px;
  background-color: #6abf4b;
  color: white;
  border-radius: 50px;
}

/* Hero Section */
.hero {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  /* padding: 60px 10%; */
  background-color: #ffffff;
  position: relative;
}

.hero-content {
  /* flex: 1; */
  width: 33vw;
  /* max-width: 500px; */
  padding-left: 60px;
  color: #000;
  top: 10px;
  z-index: 10;
}

.hero h1 {
  font-size: 3em;
  font-weight: 900;
  margin-bottom: 20px;
}

.hero p {
  font-size: 1.3em;
  margin-bottom: 30px;
}

.store-buttons {
  position: relative;
  z-index: 11;
  display: flex;
  height: 300px;
}

.hero .store-buttons img {
  width: 150px;
  margin-right: 60px;
}

.phone-mockup {
  padding-left: 60px;
  width: 33vw;
}

.phone-mockup img {
  width: 100%;
  max-width: 350px;
  margin-left: 20px;
  top: -10px;
  max-height: 700px;
  border: 2px solid #ccc;
  border-radius: 10px;
}

/* Features Section */
.features {
  top: -110px;
  padding: 60px 10%;
  text-align: center;
  background-color: white;
  z-index: 4 !important;
  position: relative;
  height: 600px;
}

.support {
  text-align: center;
  /* padding: 20px 10%; */
}

.connect-img {
  text-align: center;
  height: 200px;
}

.connect-storeType-container {
  display: flex;
  justify-content: center;
}

.connect-img img {
  margin-top: 10px;
  height: 60px;
  margin-right: 30px;
}
.fb-img {
  border-radius: 10px;
  height: 62px !important;
  margin-top: 8px !important;
}

.features h2 {
  margin-top: 120px !important;
  font-size: 2.5em;
  margin-bottom: 40px;
}

.features-grid {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}

.feature-item {
  flex: 1;
  min-width: 250px;
  padding: 20px;
  color: #000;
  border-radius: 10px;
  text-align: center;
}

.support-grid {
  flex-wrap: wrap;
  padding-top: 100px;
}

.support-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  min-width: 250px;
  color: #000;
  border-radius: 10px;
  text-align: center;
}

.support-item h4 {
  font-size: 18px;
  padding-left: 5px;
}

.support-item p {
  font-size: 16px;
  padding-left: 5px;
}

.feature-item img {
  width: 50px;
  margin-bottom: 20px;
}

.feature-item h3 {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.feature-item p {
  margin-bottom: 10px;
  color: #333;
}

.feature-item a {
  color: #80cc27;
  text-decoration: none;
}

/* Business Solutions Section */
.business-solutions {
  padding: 60px 10%;
  background-color: #6abf4b;
  color: white;
  text-align: center;
}

.business-solutions h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.business-solutions p {
  margin-bottom: 40px;
}

.solutions-grid {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}

.solution-item {
  flex: 1;
  min-width: 250px;
  padding: 20px;
  background-color: white;
  color: #333;
  border-radius: 10px;
  text-align: center;
}

.solution-item h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

/* Call to Action */
.call-to-action {
  padding: 60px 10%;
  background-color: #487f07;
  color: #ffffff;
  text-align: center;
  width: 80%;
  margin-left: 10%;
  border-radius: 10px;
}

.call-to-action h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.call-to-action p {
  margin-bottom: 30px;
}

.cta-buttons .btn {
  padding: 15px 30px;
  background-color: #ffffff;
  color: #6abf4b !important;
  border-radius: 50px;
  margin-right: 10px;
  text-decoration: none;
}

.cta-buttons .btn.secondary {
  background-color: transparent;
  color: #ffffff !important;
  border: 2px solid #ffffff;
}

.support-form {
  padding: 10px 10%;
  /* background-color: #487f07; */
  color: #333333;
  text-align: center;
  width: 80%;
  margin-left: 10%;
  border-radius: 10px;
}

.support-buttons .btn {
  margin-top: 30px;
  padding: 10px 40px;
  background-color: #ffffff;
  color: #6abf4b !important;
  border-radius: 50px;
  margin-right: 10px;
  text-decoration: none;
}

.support-buttons .btn.secondary {
  background-color: transparent;
  color: #ffffff !important;
  border: 2px solid #ffffff;
}

/* Footer */
.footer {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 40px 40px;
  background-color: #ffffff;
  color: #333333;
  text-align: center;
}

.footer .footer-logo img {
  width: 70px;
  margin-bottom: 20px;
}

.footer .footer-logo p {
  padding-left: 20px;
}

.footer .social-links a {
  margin: 0 10px;
}

.footer .social-links img {
  width: 30px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero {
    flex-direction: column;
    text-align: center;
    background-image: url(../home-img/WnWn-logo-transparent.png);
    background-size: contain;
    background-repeat: no-repeat;
    max-width: 95vw;
  }

  .features-grid,
  .solutions-grid {
    flex-direction: column;
    align-items: center;
  }

  .feature-item,
  .solution-item {
    width: 100%;
  }
}

.oval-bg img {
  top: 20px;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.wave-svg {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  bottom: 100px;
}
.features-logo {
  position: absolute;
  height: 600px;
  align-self: center;
  top: 50%;
  left: 50%;
  z-index: -1;
  transform: translate(-50%, -50%);
}

.qr-code {
  margin-top: 10px;
  width: 150px !important;
  display: block;
}

.buttons-storeType-container {
  padding: 10px;
  width: 170px;
  margin-right: 10px;
  background-color: #fff;
}

.hero-logo {
  position: absolute;
  height: 600px;
  align-self: center;
  left: 0;
  max-width: 50%;
}

.hero-wave-logo {
  position: absolute;
  height: 200px;
  align-self: center;
  right: -10px;
  top: 60px;
  z-index: 5;
  overflow: hidden;
}

/* Mobile view */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger span {
  width: 35px;
  height: 3px;
  background-color: #487f07;
  margin: 4px 0;
}
.menu-link-admin {
  margin-bottom: -10px !important;
  font-weight: 800 !important;
}
#layout-menu-admin {
  display: none;
}
.admin-icon {
  font-size: 40px;
  margin-bottom: 20px;
}
.qr-storeType-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33vw;
}
@media (max-width: 1240px) {
  .phone-mockup {
    padding-left: 20px;
  }
}

@media (max-width: 1100px) {
  .hero-content {
    width: calc(95vw - 15px) !important;
    padding: 20px;
    overflow: hidden;
  }
  .phone-mockup {
    width: 95vw !important;
  }
  .qr-storeType-container {
    width: 100vw !important;
  }
}
@media (max-width: 768px) {
  .buttons-storeType-container {
    display: block;
    width: 140px;
    margin-right: 20px;
    padding-left: 0px;
  }
  .store-buttons {
    justify-content: center;
    width: 100%;
    overflow: hidden;
  }
  .hero .store-buttons img {
    width: 120px !important;
    margin-left: 10px !important;
    margin-right: 0;
  }
  .qr-code {
    width: 120px !important;
  }
  .hamburger {
    display: flex;
    padding-right: 10px;
  }
  #layout-menu-admin {
    display: block;
  }
  .header {
    padding: 20px 5%;
    background-color: white;
  }
  .hero {
    padding-left: 10px;
    padding-right: 10px;
    width: 100vw;
  }
  .hero h1 {
    font-size: 2em;
  }
  .hero p {
    font-size: 1em;
  }
  .phone-mockup {
    padding-left: 0px;
    margin-top: 50px;
    margin-bottom: 50px;
    max-width: 350px;
  }
  .hero-logo {
    top: 15% !important;
    height: 300px !important;
  }
  .call-to-action {
    border-radius: 0px;
  }
  .oval-bg img {
    display: none;
  }
  .hero-wave-logo {
    display: none;
  }
  .hero-logo {
    display: none;
  }
  .features {
    padding: 20px 10px;
    width: 95vw;
  }
  .connect-img {
    margin-top: 450px;
    justify-content: center;
  }
  .connect-storeType-container {
    display: flex;
    flex-direction: column;
    width: 100px;
    justify-content: center !important;
    width: 100vw;
    align-items: center;
  }
  .connect-storeType-container img {
    margin-top: 15px;
  }
  .fb-img {
    border-radius: 10px;
    height: 62px !important;
    /* margin-top: 8px !important; */
    margin-top: 10px !important;
  }
  .features h2 {
    font-size: 2em;
    text-align: center;
    margin-bottom: 40px;
  }
  .features-logo {
    display: none;
  }
  .call-to-action {
    margin-top: 250px;
    width: 100vw;
    overflow: hidden;
    margin-left: 0px;
    padding: 60px 2%;
  }

  .support-form {
    width: 100vw;
    overflow: hidden;
    margin-left: 0px;
    padding: 10px 2%;
  }
  .footer {
    flex-direction: column;
    align-items: center;
  }
  .social-links {
    margin-top: 20px;
  }
  .footer-logo {
    margin-bottom: 20px;
  }
  .nav {
    display: none !important;
  }
  .feature-item {
    background-image: url(../home-img/WnWn-logo-transparent.png);
    background-size: contain;
    padding-top: 55px;
    padding-bottom: 55px;
    background-repeat: no-repeat;
    margin-left: 10px;
  }
}

@media (max-width: 400px) {
  .phone-mockup {
    max-width: 320px;
  }

  .hamburger {
    display: flex;
    padding-right: 0;
  }
}

.storeType-container {
  max-width: 500px !important;
  margin: 0 auto;
  padding: 0 20px;
}

.storeType-header {
  background-color: #7ed957;
  padding: 15px;
  color: white;
  display: flex;
  align-items: center;
}

.storeType-header h1 {
  font-size: 18px;
  margin: 0;
}

.content h2 {
  font-size: 16px;
  color: #7ed957;
}

.option {
  background-color: white;
  border: 1px solid #ddd;
  padding: 10px;
  margin: 15px 0 !important;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.option label {
  font-size: 14px;
  color: #333;
}

.option input {
  margin: 0;
  width: 16px;
  height: 16px;
}

.button-container {
  margin-top: 20px;
  text-align: center;
}

.button-container button {
  background-color: #7ed957;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
}

.button-container button:hover {
  background-color: #68c44f;
}

.back-btn {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #fff;
  cursor: pointer;
}

.form-container {
  text-align: center;
  background-color: red;
  max-width: 600px;
}

.form-container h2 {
  font-size: 1.2rem;
  color: #32cd32;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
  position: relative;
}

.form-group input {
  width: 100%;
  padding: 12px 15px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.password-field input {
  padding-right: 60px; /* Space for the "Show" button */
}

.show-password {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 0.9rem;
  color: #32cd32;
  cursor: pointer;
}

/* Submit Button */
.submit-btn {
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  background-color: #32cd32;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
}

.submit-btn:hover {
  background-color: #28a428;
}

/* Responsive Design */
@media (max-width: 768px) {
  .header h1 {
    font-size: 1.2rem;
  }

  .submit-btn {
    font-size: 0.9rem;
  }

  .form-buttons {
    flex-direction: column-reverse;
  }

  .back-button {
    margin-top: 10px !important;
  }
}

.grey-text {
  font-size: 16px;
  color: grey;
}
