/* styles.css */

.container-support-page {
  display: flex;
  flex-wrap: wrap;
  /* max-width: 1200px; */
  margin: 0 auto;
}

.left-section {
  flex: 5;
  padding: 20px;
  height: 100vh;
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.right-section {
  flex: 4;
  background-color: #6abf4b; /* Blue background */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  height: 100vh;
}

.form-card {
  background-color: #fff;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
}

.right-section h1 {
  font-size: 1.4rem;
  margin-bottom: 20px;
  color: #000;
}

.right-section h2 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #000;
}

.left-section h1 {
  font-weight: 500;
  margin-bottom: 40px;
  color: #000;
}

.contact-method {
  margin-bottom: 20px;
  background-color: #ffffff;
  padding: 20px;
  width: 50%;
}

.contact-method h2 {
  font-size: 1.2rem;
}

.contact-method a {
  text-decoration: none;
  color: red !important;
  font-weight: bold;
}

.contact-method a:hover {
  text-decoration: underline;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group-support {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 5px;
  font-weight: bold;
}

input,
textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

textarea {
  resize: none;
}

.privacy-notice {
  font-size: 0.9rem;
  color: #666;
}

.privacy-notice a {
  color: red;
  text-decoration: none;
}

.privacy-notice a:hover {
  text-decoration: underline;
}

.recaptcha-placeholder {
  background-color: #f0f0f0;
  padding: 10px;
  text-align: center;
  border: 1px dashed #ccc;
  border-radius: 5px;
}

.submit-btn {
  background-color: red;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.submit-btn:hover {
  background-color: darkred;
}

/* Responsive Design */
@media (max-width: 768px) {
  .contact-method {
    width: 100%;
  }
  .container-support-page {
    flex-direction: column;
  }

  .left-section,
  .right-section {
    flex: 1;
    padding: 20px;
  }

  .right-section {
    padding: 20px 10px;
  }

  .form-card {
    padding: 20px;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.2rem;
  }

  .submit-btn {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}
