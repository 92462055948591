:root {
  --primary-color: #80cc27;
  --primary-light: #80cc27;
  --primary-font: "Poppins";
}

/* Fonts */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/poppins/poppins-regular.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: url(../fonts/poppins/poppins-medium.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: url(../fonts/poppins/poppins-semi.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/poppins/poppins-bold.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

body {
  font-family: var(--primary-font);
  background-color: #fff;
  color: #323b4b;
  font-size: 14px;
  line-height: 1.4;
}

.bg-none {
  background: none !important;
}

.bg-primary-color {
  background: var(--primary-color) !important;
}

.primary-color {
  color: var(--primary-color) !important;
}

.bg-f9 {
  background: #f9fafc !important;
}

.text-primary {
  color: var(--primary-color) !important;
}

.text-body {
  color: #6d778e !important;
}

.text-1e {
  color: #171725 !important;
}

.light {
  font-weight: 300 !important;
}

.normal {
  font-weight: 400 !important;
}

.medium {
  font-weight: 500 !important;
}

.semi {
  font-weight: 600 !important;
}

.bold {
  font-weight: 700 !important;
}

.exbold {
  font-weight: 800 !important;
}

.f-black {
  font-weight: 900 !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

a {
  color: var(--primary-color) !important;
}

a:hover {
  color: var(--primary-light);
}

.fill-primary {
  fill: var(--primary-color);
}

.btn {
  background: var(--primary-color);
  color: #fff !important;
  border: 1px solid var(--primary-color);
  padding: 10px 25px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  transition: background 0.4s;
}

.btn:hover {
  background: var(--primary-light);
  border-color: var(--primary-light);
  color: #fff !important;
  transition: background 0.4s;
}

.btn-circle {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: var(--primary-color);
  color: #fff;
  font-size: 20px;
  margin: 0 10px;
}

.btn-circle img {
  height: 18px;
}

.btn-circle:hover {
  color: #fff;
}

.btn-outline-primary {
  color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
  background: transparent;
}

.btn-outline-primary:hover {
  color: #fff;
  border-color: var(--primary-color);
  background: var(--primary-color);
}

.btn-outline {
  color: var(--primary-color) !important;
  border-radius: 50px;
  padding: 3px 20px;
  border: 1px solid rgba(4, 4, 21, 0.1);
  background: transparent;
}

.btn-outline:hover {
  color: #fff;
  border-color: var(--primary-color);
  background: var(--primary-color);
}

.btn-lg {
  font-size: 18px;
}

.btn-xl {
  font-size: 16px;
}

.br-5 {
  border-radius: 5px !important;
}

.br-10 {
  border-radius: 10px !important;
}

.br-15 {
  border-radius: 15px !important;
}

.br-20 {
  border-radius: 20px !important;
}

.theme-scrollbar {
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 0px;
  scrollbar-color: #ddd #f5f5f5;
  scrollbar-width: thin;
}

.theme-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  background-color: transparent;
}

.theme-scrollbar::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.theme-scrollbar::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 3px;
}

.ps__rail-y,
.ps__rail-x {
  padding: 0;
}

.nav-link:hover,
.nav-link:focus {
  color: var(--primary-color);
}

/* MENU */
.menu-vertical .app-brand {
  padding-right: 1.2rem;
  padding-left: 1.2rem;
}

.layout-navbar-fixed .layout-navbar.navbar-detached {
  height: auto;
  padding: 1rem;
  width: calc(100% - 16.25rem);
  color: #1e2a42;
  background: #fff;
  margin: 0;
  max-width: 100%;
  border-radius: 0;
}

.bg-menu-theme {
  background-color: var(--primary-color);
  color: #8a94a6;
}

.menu-vertical .menu-block {
  padding: 0;
}

.bg-menu-theme .menu-inner > .menu-item {
  position: relative;
}

.bg-menu-theme .menu-inner > .menu-item.active::before {
  content: "";
  position: absolute;
  left: 0;
  width: 0.3rem;
  height: 1.9rem;
  top: 6px;
  border-radius: 0.375rem;
  background: #fff;
  z-index: 1;
}

.bg-menu-theme .menu-inner .menu-item .menu-link {
  border-radius: 0;
  font-size: 14px;
  margin: 0;
  color: #333333 !important;
  font-weight: 500;
  padding: 0.7rem 1rem 0.7rem 1.2rem;
  margin-bottom: 10px;
}

.bg-menu-theme .menu-inner .menu-item.open > .menu-link,
.bg-menu-theme .menu-inner .menu-item .menu-link:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.bg-menu-theme
  .menu-inner
  .menu-sub
  > .menu-item.active
  > .menu-link.menu-toggle {
  background-color: rgba(8, 142, 172, 0.1);
}

.bg-menu-theme .menu-inner .menu-sub > .menu-item.active .menu-icon {
  color: var(--primary-color);
}

.bg-menu-theme .menu-inner > .menu-item.active > .menu-link {
  color: #000000 !important;
}

.menu-vertical .quick-links .menu-inner .menu-item a.menu-link {
  color: var(--primary-color) !important;
}

.bg-menu-theme .quick-links .menu-inner .menu-item .menu-link {
  font-size: 14px;
}

.quick-links ul li a .menu-icon {
  background: var(--primary-color);
  border-radius: 50%;
  color: #fff;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.bg-menu-theme .menu-sub > .menu-item > .menu-link:before {
  content: "";
  position: absolute;
  left: 1.4375rem;
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 50%;
  background: #b4bdc6;
}

.bg-menu-theme
  .menu-sub
  > .menu-item.active
  > .menu-link:not(.menu-toggle)::before {
  background: var(--primary-color);
  box-shadow: 0 0 0 2px #fff;
  border: 3px solid #e7e7ff !important;
  left: 1.1875rem;
  width: 0.875rem;
  height: 0.875rem;
}

.layout-menu-toggle .nav-link {
  color: var(--primary-color);
}

@media (min-width: 1200px) {
  .menu-vertical {
    padding-left: 0px;
    width: 13rem;
  }

  .layout-menu-fixed:not(.layout-menu-collapsed) .layout-page,
  .layout-menu-fixed-offcanvas:not(.layout-menu-collapsed) .layout-page {
    padding-left: 13rem;
  }

  .layout-navbar-fixed:not(.layout-menu-collapsed)
    .layout-content-navbar:not(.layout-without-menu)
    .layout-navbar,
  .layout-menu-fixed.layout-navbar-fixed:not(.layout-menu-collapsed)
    .layout-content-navbar:not(.layout-without-menu)
    .layout-navbar,
  .layout-menu-fixed-offcanvas.layout-navbar-fixed:not(.layout-menu-collapsed)
    .layout-content-navbar:not(.layout-without-menu)
    .layout-navbar {
    left: 13rem;
    width: calc(100% - 13rem);
  }

  .menu-vertical .menu-block,
  .menu-vertical .menu-inner > .menu-item,
  .menu-vertical .menu-inner > .menu-header {
    width: 100%;
  }
}

@media (max-width: 1199.98px) {
  .app-brand .layout-menu-toggle {
    display: none !important;
  }

  .layout-menu-expanded .app-brand .layout-menu-toggle {
    display: block !important;
  }
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: var(--primary-color);
  background-color: rgba(67, 89, 113, 0.1);
}

.menu-vertical .menu-icon {
  width: 1.2rem;
  font-size: 1rem;
}

/* NOTIFICATIONS */
.profile-menu .dropdown-toggle::after {
  border: 0;
  content: "\f0d7";
  font-family: "Font Awesome 5 Pro";
  transform: none;
  width: auto;
  height: auto;
  margin-top: 0;
}

.profile-menu li .nav-link {
  font-size: 13px;
  padding: 0;
}

.avatar {
  height: auto;
  width: 1.9rem !important;
}

.w-px-40 {
  width: 30px !important;
}

.dropdown-notification .nav-link i {
  font-size: 18px;
}

.dropdown-notification .dropdown-menu {
  min-width: 300px;
}

.message-center {
  max-height: 200px;
  overflow: hidden;
  overflow-y: hidden;
  overflow-y: auto;
  border-radius: 5px;
}

.notify {
  position: relative;
  top: -2px;
  right: -5px;
}

.notify .point {
  width: 6px;
  height: 6px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  background-color: var(--primary-color);
  position: absolute;
  right: 6px;
  top: -10px;
}

.notify .heartbit {
  position: absolute;
  top: -20px;
  right: -4px;
  height: 25px;
  width: 25px;
  z-index: 10;
  border: 5px solid var(--primary-color);
  border-radius: 70px;
  -moz-animation: heartbit 1s ease-out;
  -moz-animation-iteration-count: infinite;
  -o-animation: heartbit 1s ease-out;
  -o-animation-iteration-count: infinite;
  -webkit-animation: heartbit 1s ease-out;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@keyframes heartbit {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }

  25% {
    -webkit-transform: scale(0.1);
    opacity: 0.1;
  }

  50% {
    -webkit-transform: scale(0.5);
    opacity: 0.3;
  }

  75% {
    -webkit-transform: scale(0.8);
    opacity: 0.5;
  }

  to {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

.ls-2 {
  letter-spacing: 2px;
}

.mail-desc {
  font-size: 14px;
}

.card {
  border: 1px solid #ebebec;
  border-radius: 20px;
  box-shadow: none;
}

.card-header {
  color: #040415;
}

.bg1 {
  background-color: #154493;
}

.bg2 {
  background-color: #3c22d4;
}

.bg3 {
  background-color: #bb7a00;
}

.bg4 {
  background-color: #006747;
}

.card-header {
  font-size: 16px;
}

.analytics-block .card {
  color: #fff;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.analytics-block .card:before {
  content: "";
  position: absolute;
  top: -60px;
  right: -40px;
  width: 100px;
  height: 100px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  outline: 1px solid rgba(255, 255, 255, 0.2);
  outline-offset: 30px;
}

.analytics-block .card-body {
  flex: 1 1 auto;
  padding: 2rem 1rem;
}

.analytics-block .card-icon {
  margin-right: 15px;
}

.table:not(.table-dark) th {
  color: #a5a5a8;
}

.table th {
  text-transform: none;
  font-size: 14px;
  letter-spacing: 0;
  font-weight: 600;
}

table.dataTable thead > tr > th.sorting::before,
table.dataTable thead > tr > th.sorting::after,
table.dataTable thead > tr > th.sorting_asc::before,
table.dataTable thead > tr > th.sorting_asc::after,
table.dataTable thead > tr > th.sorting_desc::before,
table.dataTable thead > tr > th.sorting_desc::after,
table.dataTable thead > tr > th.sorting_asc_disabled::before,
table.dataTable thead > tr > th.sorting_asc_disabled::after,
table.dataTable thead > tr > th.sorting_desc_disabled::before,
table.dataTable thead > tr > th.sorting_desc_disabled::after,
table.dataTable thead > tr > td.sorting::before,
table.dataTable thead > tr > td.sorting::after,
table.dataTable thead > tr > td.sorting_asc::before,
table.dataTable thead > tr > td.sorting_asc::after,
table.dataTable thead > tr > td.sorting_desc::before,
table.dataTable thead > tr > td.sorting_desc::after,
table.dataTable thead > tr > td.sorting_asc_disabled::before,
table.dataTable thead > tr > td.sorting_asc_disabled::after,
table.dataTable thead > tr > td.sorting_desc_disabled::before,
table.dataTable thead > tr > td.sorting_desc_disabled::after {
  opacity: 0.4;
  color: #040415;
}

table.dataTable thead > tr > th.sorting_asc::before,
table.dataTable thead > tr > th.sorting_desc::after,
table.dataTable thead > tr > td.sorting_asc::before,
table.dataTable thead > tr > td.sorting_desc::after {
  opacity: 1;
}

.badge {
  text-transform: none;
  border-radius: 50px;
  font-weight: 500;
  padding: 0.82em 0.9em;
}

.page-item .page-link {
  color: #b0b7c3;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-item.active .page-link {
  margin: 0 0.1rem 0 0.3rem;
  color: var(--primary-color);
}

.page-link:hover {
  background: var(--primary-color);
  color: #fff;
}

.text-40 {
  color: #040415;
}

.user-name {
  font-size: 14px;
}

.data-table-block th {
  position: relative;
}

.data-table-block th::after,
.data-table-block th::before {
  content: "\f0d8";
  font-family: "Font Awesome 5 Pro";
  font-weight: 700;
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 12px;
}

.data-table-block th::after {
  content: "\f0d7";
  top: 15px;
}

.pagination li a {
  color: #b0b7c3;
  padding: 8px 12px;
  font-weight: 500;
}

.pagination li.active a,
.pagination li a:hover {
  color: var(--primary-color);
}

.pagination .next a,
.pagination .prev a {
  background-color: var(--primary-color);
  color: #fff !important;
}

.pagination .next a:hover,
.pagination .prev a:hover {
  background: var(--primary-light);
  color: #fff;
}

/* AUTHENTICATION
------------------------ */
.authentication-wrapper {
  display: flex;
  flex-basis: 100%;
  min-height: 100vh;
  width: 100%;
}

.authentication-wrapper .authentication-inner {
  width: 100%;
}

.authentication-wrapper .authentication-inner .app-brand {
  position: absolute;
  top: 1rem;
  left: 1.5rem;
  overflow: visible;
}

.authentication-wrapper.authentication-cover {
  align-items: flex-start;
}

.authentication-wrapper.authentication-cover .authentication-inner {
  height: 100vh;
}

.authentication-wrapper.authentication-cover .authentication-inner img {
  height: 100vh;
  object-fit: cover;
}

.form-check-input:checked[type="checkbox"] {
  background-color: var(--primary-color);
}

.authentication-wrapper .form-control {
  border: 0;
  height: 50px;
  padding: 0;
  box-shadow: none !important;
  border-radius: 0;
}

.authentication-wrapper .form-control:focus {
  border: 0;
  box-shadow: none !important;
  outline: 0;
}

.input-group:focus-within {
  box-shadow: none !important;
}

.style-line .line {
  display: block;
  height: 1px;
  width: 100%;
  position: relative;
  background-color: #ebeaed;
  -webkit-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
  z-index: 1;
}

.style-line .line::before {
  content: "";
  display: block;
  width: 0;
  height: 1px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: var(--primary-color);
  -webkit-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.style-line:hover .line::before,
.style-line .form-control:focus ~ .line::before {
  width: 100%;
}

.pp-type .form-check-input {
  opacity: 0;
}

.pp-type .form-check {
  padding-left: 0;
  min-height: auto;
  cursor: pointer;
  margin: 0 10px 10px 0;
}

.pp-type .form-check .form-check-label {
  background: #fff;
  border-radius: 3px;
  padding: 5px 20px;
  margin-bottom: 0;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  font-weight: 500;
  cursor: pointer;
}

.pp-type .form-check-input:checked ~ .form-check-label,
.pp-type .form-check-label:hover {
  background: var(--primary-color);
  color: #fff;
}

.pp-type .remove-hover:hover {
  color: var(--primary-color) !important;
  background: #fff !important;
  cursor: default;
}
/* Wizard form */
.wizard-fieldset {
  opacity: 0;
  visibility: hidden;
  transform: translateY(20px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.wizard-fieldset.show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

/* MEDIA CSS
------------------------- */
@media (min-width: 768px) {
  .authentication-img {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 50%;
  }

  .wizard-fieldset {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
  }
}

@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container,
  .layout-navbar-fixed .layout-navbar.navbar-detached {
    max-width: 100%;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}

/* @media (max-width: 1600px) {
  .menu-vertical {
    margin-left: 1rem;
  }
} */
@media (max-width: 1199.88px) {
  body {
    overflow-x: hidden;
  }

  .container,
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .bg-menu-theme {
    overflow-y: auto;
  }

  .menu-vertical {
    width: 280px;
    /* padding: 15px; */
    padding-left: 0;
  }

  .menu-vertical .menu-block,
  .menu-vertical .menu-inner > .menu-header,
  .menu-vertical .menu-inner > .menu-item {
    width: 100%;
  }

  .layout-navbar-fixed .layout-navbar.navbar-detached {
    width: 100% !important;
  }

  .user-name {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .layout-navbar {
    padding: 1rem;
  }

  .btn {
    padding: 8px 20px;
  }

  .main-card {
    background: transparent;
    padding: 0;
  }

  .main-card > .card-body {
    padding: 0;
  }

  .flex-reverse-mob {
    flex-direction: column-reverse;
  }

  .card-body {
    padding: 15px;
  }

  .btn-circle {
    width: 35px;
    height: 35px;
  }

  .user-name {
    font-size: 14px;
    display: none;
  }

  h6,
  .h6 {
    font-size: 14px;
  }

  .table-responsive-xs {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .text-nowrap-xs {
    white-space: nowrap;
  }

  .profile-menu li .nav-link {
    font-size: 12px;
    padding: 0;
  }

  .layout-content-navbar .content-wrapper {
    margin-top: 10px;
  }

  .table > :not(caption) > * > * {
    padding: 0.5rem 0.9rem;
  }

  .btn-outline {
    padding: 5px 15px;
  }

  .authentication-wrapper.authentication-cover .authentication-inner img {
    height: 40vh;
    object-fit: cover;
  }

  .authentication-wrapper.authentication-cover .authentication-inner {
    height: auto;
  }

  .login-ss {
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  .user-name {
    text-align: left;
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  font-size: 1rem !important;
}

@media (min-width: 767px) {
  .notification-drop {
    margin-left: -200px;
  }
}

.show-sidebar {
  transform: translate3d(0, 0, 0) !important;
  transition: width 0.3s;
  z-index: 20001;
}

.layout-overlay-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  left: 0;
  opacity: 0.5;
  cursor: pointer;
  background: #435971;
  z-index: 20000;
}

.loading-text {
  margin-top: 2rem;
  font-weight: 500;
  font-size: 1rem;
  display: flex;
  justify-content: center;
}

.user-profile-image {
  height: 130px;
  width: 130px;
  border-radius: 50% !important;
  padding: 15px;
  /* border: 1px solid #016646; */
}

@media (min-width: 767px) {
  .user-name {
    width: 160px !important;
  }
}

.table-btn {
  padding: 4px 18px !important;
  font-size: 12px !important;
}

.disabled-icon {
  color: #a1acb8 !important;
}

.page-container {
  overflow: hidden;
}

th::after {
  content: none !important;
}

th::before {
  content: none !important;
}

.user-name-first {
  font-size: 14px !important;
}

@media (max-width: 767px) {
  .user-name-first {
    display: none !important;
  }
}

.organization-pic {
  width: 10rem;
  border-radius: 0 !important;
  padding-right: 1rem !important;
}

.add-member-modal {
  background-color: #fff;
  border: 1px solid #006747;
  border-radius: 10px;
  height: 100% !important;
  overflow: hidden;
  min-width: 400px;
  min-height: 300px !important;
  justify-content: center;
}

.add-invoice-modal {
  min-height: 520px !important;
}
.buss-details-modal {
  background-color: #fff;
  border: 1px solid rgb(51, 3, 47);
  border-radius: 10px;
  height: 100% !important;
  width: min(95vw, 600px);
  min-height: 100% !important;
  max-height: 90vh;
  padding-bottom: 30px;
  overflow: auto;
  overflow-x: hidden;
}

.add-buss-modal {
  background-color: #fff;
  border: 1px solid #006747;
  border-radius: 10px;
  height: 100% !important;
  min-height: 100% !important;
}

.add-member-modal .form-control {
  width: 350px !important;
}

.add-member-modal .input-group-form {
  width: 300px !important;
}

.add-buss-modal .text-area {
  width: 100% !important;
  height: 120px !important;
}

.add-org-modal .form-control {
  width: 320px !important;
}
.add-org-modal .input-group {
  width: 101% !important;
}

.add-org-modal .text-area {
  width: 100% !important;
}

.mobile-margin-top {
  margin-top: 20px !important;
}

@media (max-width: 767px) {
  .add-member-modal {
    width: 95vw !important;
    min-height: 90vh !important;
    height: 90vh !important;
  }

  .add-buss-modal {
    width: 95vw !important;
    min-height: 90vh !important;
    height: 90vh !important;
  }

  .add-member-modal .form-control {
    width: 80vw !important;
    height: 42px !important;
  }

  .add-buss-modal .form-control {
    width: 80vw !important;
    height: 42px !important;
  }

  .wizard-next-btn {
    margin-top: 20px;
  }

  .add-buss-modal .text-area {
    width: 100% !important;
    height: 80px !important;
  }

  .add-org-modal .form-control {
    width: 80vw !important;
  }
  .add-org-modal .input-group {
    width: 101% !important;
  }

  .add-member-modal .input-group-form {
    width: 60vw !important;
  }
}

.edit-form-control {
  font-size: 14px !important;
}

.grey-btn {
  background-color: #ccc;
  color: #333 !important;
  border: none;
}
.grey-btn:hover {
  background-color: #ccc;
  color: #333 !important;
  border: none;
}
.dropdown-menu-top {
  margin-left: -140px !important;
  margin-top: -240px !important;
}

.edit-icon {
  cursor: pointer;
  font-size: 14px !important;
  color: #80cc27 !important;
  padding-top: 5px;
  text-align: center;
  width: 100%;
  margin-left: -15px !important;
}

.edit-icon-member {
  width: 130px !important;
  position: relative;
  font-size: 14px !important;
  cursor: pointer;
  color: #80cc27 !important;
  text-align: center;
  width: 100%;
}

.edit-delete {
  margin-top: 30px;
  cursor: pointer;
  font-size: 18px !important;
  color: #006747 !important;
  position: absolute;
}

.ads-list-pic {
  width: 7rem !important;
}

.image-ads-upload {
  width: 100%;
  height: 6rem;
  border-radius: 4px;
  color: #006747;
  background-color: #ccc;
  display: inline-block;
  padding: 35px 12px;
  cursor: pointer;
}

.add-image-show {
  /* width: 100%; */
  height: 10rem;
  border-radius: 4px;
}
.add-ads-edit {
  position: absolute;
  color: #80cc27;
  background-color: #fff;
  border-radius: 50%;
  padding: 3px;
  margin-left: -25px;
  margin-top: 5px;
  cursor: pointer;
}

.forgat-pwd {
  width: 101% !important;
}

.delete-modal {
  background-color: #fff;
  border: 1px solid #006747;
  border-radius: 10px;
  height: 200px !important;
  width: min(95vw, 400px);
  padding-top: 20px;
  justify-content: center;
  align-items: center;
  min-height: 200px !important;
}

.ads-pic {
  width: 250px !important;
}

.buss-details-img {
  height: 240px; /* Set your desired fixed height */
}

.img-center {
  display: flex;
  justify-content: center;
  /* flex-direction: column; */
  padding-top: 50px;
}

.buss-add-box {
  padding: 10px;
  background: rgb(51, 3, 47);
  background: linear-gradient(
    90deg,
    rgb(207, 143, 201) 8%,
    rgb(205, 179, 216) 50%,
    rgb(211, 197, 225) 81%
  );
  min-height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.icon-buss {
  color: #03bb82 !important;
}

.login-page {
  background-image: url(../images/login.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh !important;
}
.container-home {
  /* background-image: url(../images/home.jpeg); */
  background-size: cover;
  background-repeat: no-repeat;
  height: 100% !important;
}

.login-div {
  height: 350px;
  width: 500px;
  background-color: #fff;
  margin: auto;
  border: 1px solid #80cc27;
  border-radius: 10px;
}

.signup-div {
  height: 650px;
  width: 500px;
  background-color: #fff;
  margin: auto;
  overflow: hidden;
}

.interest-div {
  height: 500px;
  width: 500px;
  background-color: #fff;
  margin: auto;
}

.remove-cursor {
  cursor: default !important;
}

.status-div {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 40px;
  /* padding-top: 10px; */
}

.status-div li {
  color: #aaaaaa;
  display: block;
  position: relative;
}

.status-div li input[type="radio"] {
  position: absolute;
  visibility: hidden;
}

.status-div li label {
  display: block;
  position: relative;
  font-weight: 300;
  font-size: 1em;
  padding-left: 45px;
  margin-top: 0;
  z-index: 9;
  cursor: pointer;
  -webkit-transition: all 0.25s linear;
}

.status-div li:hover label {
  color: #006747;
}

.status-div li .check {
  display: block;
  position: absolute;
  border: 3px solid #aaaaaa;
  border-radius: 100%;
  height: 20px;
  width: 20px;
  top: 0;
  left: 20px;
  z-index: 5;
  transition: border 0.25s linear;
  -webkit-transition: border 0.25s linear;
}

.status-div li:hover .check {
  border: 3px solid #006747;
}

.status-div li .check::before {
  display: block;
  position: absolute;
  content: "";
  border-radius: 100%;
  height: 12px;
  width: 12px;
  top: 1px;
  left: 1px;
  margin: auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
}

input[type="radio"]:checked ~ .check {
  border: 3px solid #006747;
}

input[type="radio"]:checked ~ .check::before {
  background: #006747;
}

input[type="radio"]:checked ~ label {
  color: #006747;
}

.status-div li .reject {
  display: block;
  position: absolute;
  border: 3px solid #aaaaaa;
  border-radius: 100%;
  height: 20px;
  width: 20px;
  top: 0;
  left: 20px;
  z-index: 5;
  transition: border 0.25s linear;
  -webkit-transition: border 0.25s linear;
}

.status-div li:hover .reject {
  border: 3px solid red;
}

.status-div li .reject::before {
  display: block;
  position: absolute;
  content: "";
  border-radius: 100%;
  height: 12px;
  width: 12px;
  top: 1px;
  left: 1px;
  margin: auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
}

input[type="radio"]:checked ~ .reject {
  border: 3px solid red;
}

input[type="radio"]:checked ~ .reject::before {
  background: red;
}

input[type="radio"]:checked ~ .reject-label {
  color: red;
}

.reject-label:hover {
  color: red !important;
}

.status-div li:hover .reject-label {
  color: red !important;
}
.outline-btn {
  background-color: #fff;
  color: #80cc27 !important;
}

.delete-icon {
  color: red !important;
}
.code-document-box {
  width: 100%;
  background-color: "red" !important;
  display: flex;
  padding: 5px;
  padding-top: 10px;
  margin-bottom: 15px !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}
.react-datepicker__view-calendar-icon input {
  padding: 6px 10px 5px 15px !important;
}
.form-control-date {
  /* height: 40px; */
  border-radius: 10px;
  /* border: 1px solid var(--primary-color); */
  background-color: #f8f8f8;
  color: #000;
}
.react-datepicker-popper {
  z-index: 100;
}
.form-control-transparent {
  background-color: transparent !important;
  color: #000;
}
.form-control-transparent:focus {
  color: #000;
}
.form-check-input:checked {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
input[type="radio"]:checked ~ label {
  color: var(--primary-color);
}
.desc-cat {
  color: black;
  font-size: 14px;
  font-weight: 600;
  display: block;
}

.tag {
  color: #333;
  font-size: 16px;
  font-weight: 600;
}
.tag-val {
  color: #80cc27;
  font-weight: 600;
  font-size: 16px;
  margin-top: -15px;
}
.desc-invoice {
  font-size: 16px;
  font-weight: 600;
  display: "block";
  margin-bottom: 0 !important;
}
.val-invoice {
  font-size: 15px;
  display: "block";
}

@media (min-width: 767px) {
  .invoice-container {
    display: flex;
    justify-content: center; /* Center the child content horizontally */
  }

  /* .invoice-content {
    left: 50%;
    transform: translateX(-50%);
  } */
}

.text-bold {
  font-weight: 600;
}
.table-responsive-xs {
  overflow: auto !important;
}
.buss-details-content {
  padding-left: 30px;
}
.invoice-content-top {
  padding-top: 20px;
  padding-left: 40px;
}
.attechments-img {
  height: 140px;
  border-radius: 10px;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .rating-box {
    margin-top: -10px;
  }
  .address-box {
    padding-top: 15px;
  }
  .price-box {
    padding-top: 10px;
  }
  .description-box {
    margin-top: 10px !important;
  }
  .buss-details-content {
    padding-left: 15px;
  }
  .earinigs-box {
    padding-right: 20px;
  }
  .invoice-content-top {
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
  }
  .new-invoice {
    position: absolute;
    left: 40%;
  }
  .add-new-bag {
    padding-top: 60px;
  }
  .new-bag {
    position: absolute;
    left: 40%;
    top: 30px;
  }
}

@media (max-width: 1091px) {
  .attechments-img {
    height: 120px;
  }
}
@media (max-width: 886px) {
  .attechments-img {
    height: 100px;
  }
}
@media (max-width: 767px) {
  .attechments-img {
    height: 150px;
  }
}

@media (max-width: 600px) {
  .new-bag {
    left: 30%;
  }
}

@media (max-width: 390px) {
  .new-invoice {
    margin-left: 30px;
  }
}
.orders-filters {
  cursor: pointer;
}

.orders-filters.active {
  color: #80cc27;
  border-bottom: 2px solid #80cc27;
  padding-bottom: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.orders-filters:hover {
  /* cursor: pointer; */
  color: #80cc27;
}

.sidebar-header {
  background-color: #ffffff;
  height: 80px;
}

@media (max-width: 1200px) {
  .sidebar-header {
    margin-bottom: 10px;
  }
}
